<template>
  <div class="h-100 overflow-auto pt-5 pb-3 px-3">
    <div class="text-center">
      <img src="@/assets/logo_2.svg" class="mb-5" />

      <p class="h4 fw-bold">Terlupa Kata Laluan?</p>
      <p class="h6 text-muted">Sila masukkan e-mel anda</p>
    </div>

    <div class="forgot-form">
      <div class="mb-3">
        <input
          type="email"
          class="form-control text-center"
          id="models.email"
          v-model="models.email"
          :disabled="isLoading"
        />
      </div>
      <button
        class="btn btn-success text-light mx-auto d-block"
        @click="submit()"
        :disabled="isLoading"
      >
        <span v-if="isLoading" class="spinner-border spinner-border-sm"></span>
        <span v-else>Hantar</span>
      </button>
      <div class="mt-4 text-center">
        <router-link to="/auth/login" class="text-muted">
          Kembali ke daftar masuk
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Helper from "@/utils/helper";
import Swal from "sweetalert2";
import API from "@/utils/API";

export default {
  data() {
    return {
      isLoading: false,
      models: {
        email: "",
      },
    };
  },
  methods: {
    async submit() {
      if (!Helper.validateEmail(this.models.email)) {
        return Swal.fire({
          html: "<h5>E-mel tidak sah</h5>",
          icon: "error",
        });
      } else {
        this.isLoading = true;

        await API.forgetPassword("auth/forgot-password", this.models)
          .then((response) => {})
          .catch((error) => {
            console.error("An error occurred:", error.response);
          });

        Swal.fire({
          title: "<h4>Terima kasih</h4>",
          html: "<h6>Jika anda adalah pengguna berdaftar, anda akan menerima satu e-mel yang mengandungi pautan untuk set semula kata laluan anda.<br /><br />Sila semak folder INBOX atau SPAM untuk langkah seterusnya</h6>",
          icon: "success",
        }).then(() => {
          this.isLoading = false;

          this.$router.push("/auth/login");
        });
      }
    },
  },
};
</script>