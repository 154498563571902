<template>
  <div
    class="
      d-flex
      align-items-center
      px-5
      ms-xl-4
      pt-xl-0
      mt-xl-n5
      vh-100
      justify-content-center
    "
  >
    <form class="">
      <div v-if="user == null">
        <div class="text-center">
          <!-- <img src="./../../assets/logo_2.svg" alt="" class="mb-3" /> -->
          <img src="./../../assets/img/logo_ebimbingan.png" alt="" class="mb-3" />
          <h3 class="mb-3">Selamat Datang</h3>
          <span>{{ "Masukkan butiran yang di perlukan." }}</span>
        </div>
        <div class="form-outline mt-3">
          <label class="form-label" for="formIdentifier"
            >No Pendaftaran Haji / PEKTA / Petugas</label
          >
          <input
            type="text"
            required
            name="formIdentifier"
            v-model="firstCredential.identifier"
            class="form-control form-control-lg"            
          />
        </div>

        <div class="form-outline mt-2">
          <label class="form-label" for="formPassword">No. Kad Pengenalan</label>
          <input
            type="password"
            required
            name="formPassword"
            v-model="firstCredential.password"
            class="form-control form-control-lg"            
          />
          <p v-if="errors.confirm" class="text-danger error-text">
            {{ errors.confirm }}
          </p>
        </div>

        <div class="pt-3">
          <button
            class="btn btn-primary btn-block w-100"
            type="button"
            @click="getUser"
          >
            <div class="d-flex align-items-center" v-if="isProcessing">
              Loading...
              <div
                class="spinner-border ms-auto"
                role="status"
                aria-hidden="true"
              ></div>
            </div>
            <div v-else>Hantar</div>
          </button>
        </div>
      </div>
      <div v-else>
        <div class="text-center">
          <img src="./../../assets/logo_2.svg" alt="" class="mb-3" />
          <h3 class="mb-3">Selamat Datang</h3>
          <span
            ><strong>{{ `${user.user.full_name}` }}</strong
            >, Sila masukkan kata laluan baru.</span
          >
        </div>
        <div class="form-outline mt-3">
          <label class="form-label" for="formNemPassword">Email</label>
          <input
            type="email"
            required
            name="formNemPassword"
            v-model="credential.email"
            class="form-control form-control-lg"
            placeholder="Email"
          />
        </div>
        <div class="form-outline mt-3">
          <label class="form-label" for="formNemPassword">Kata Laluan Baru</label>
          <input
            type="password"
            required
            name="formNemPassword"
            v-model="credential.password"
            class="form-control form-control-lg"
            placeholder="******"
          />
          <p v-if="errors.password" class="text-danger error-text">Error msg</p>
        </div>

        <div class="form-outline mt-2">
          <label class="form-label" for="formConfirmNemPassword"
            >Sahkan Kata Laluan Baru</label
          >
          <input
            type="password"
            required
            name="formConfirmNemPassword"
            v-model="credential.confirm"
            class="form-control form-control-lg"
            placeholder="******"
          />
          <p v-if="errors.confirm" class="text-danger error-text">
            {{ errors.confirm }}
          </p>
        </div>

        <div class="pt-3">
          <button
            class="btn btn-primary btn-block w-100"
            type="button"
            @click="postPassword"
          >
            <div class="d-flex align-items-center" v-if="isProcessing">
              Loading...
              <div
                class="spinner-border ms-auto"
                role="status"
                aria-hidden="true"
              ></div>
            </div>
            <div v-else>Kemaskini</div>
          </button>
        </div>
      </div>
      <div class="border-top my-3"></div>
      <div class="row mt-3">
        <div class="col">
          <router-link
            to="/"
            class="
              btn btn-dark btn-block
              text-decoration-none
              lh-sm
              w-100
              h-100
            "
          >
            Log masuk sebagai pelawat
          </router-link>
        </div>
      </div>
      <div class="text-center mt-4">
        <small>
          <a href="#" class="text-muted text-decoration-underline" data-bs-toggle="modal" data-bs-target="#loginInfo">
            Klik untuk info lanjut mengenai ruangan ini
          </a>
        </small>
      </div>
    </form>
    <div 
      class="modal"
      id="loginInfo"
      tabindex="-1"
      aria-labelledby="loginInfoLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <p>
              <h5>Info Log Masuk Kali Pertama</h5>
              <ol class="mt-3">
                <li class="mb-2">
                  Bagi Bakal Haji, sila masukkan <b>No Pendaftaran Haji</b> pada ruangan Nama Pengguna, <b>No. Kad Pengenalan</b> pada ruangan No. Kad Pengenalan dan klik butang Hantar.
                </li>
                <li class="mb-2">
                  Bagi PEKTA, sila masukkan <b>No PEKTA</b> pada ruangan Nama Pengguna, <b>No. Kad Pengenalan</b> pada ruangan No. Kad Pengenalan dan klik butang Hantar.
                </li>
                <li class="mb-2">
                  Bagi Petugas Haji, sila masukkan email berdaftar dengan Tabung Haji pada ruangan Nama Pengguna, <b>No. Kad Pengenalan</b> pada ruangan No. Kad Pengenalan dan klik butang Hantar.
                </li>
                <li>
                  Bagi lain-lain pengguna, sila klik butang <b>Log masuk sebagai pelawat</b> untuk melayari sistem e-Bimbingan Haji.
                </li>
              </ol>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import API from "../../utils/API";
import Swal from "sweetalert2";

export default {
  name: "first-time",
  data() {
    return {
      isProcessing: false,
      firstCredential: {
        identifier: null,
        password: null,
      },
      credential: {
        password: null,
        confirm: null,
        email: null
      },
      errors: {
        password: null,
        confirm: null,
        noId: null,
      },
      user: null,
    };
  },
  methods: {
    postPassword() {
      if (this.credential.email === null){
        this.errors.confirm = "Sila isi ruangan emel.";
        return;
      }
      if (this.credential.password.length < 6) {
        this.errors.confirm =
          "Kata laluan perlu lebih dari 6 adjad atau simbol.";
        return;
      }
      if (this.credential.password != this.credential.confirm) {
        this.errors.confirm = "Kata laluan tidak sepadan.";
        return;
      }
      console.log("jadi")
      console.log(this.credential)

      this.errors.confirm = null;
      let data = {
        password: this.credential.password,
        confirmed: true,
      };

      if (this.credential.email) data.email = this.credential.email


      // console.log(this.user.jwt);
      let headers = {
        headers: {
          Authorization: `Bearer ${this.user.jwt}`,
        },
      };
        
      axios
        .put(
          `${process.env.VUE_APP_API_URL}/users/${this.user.user.id}`,
          data,
          headers
        )
        .then((res) => {
          console.log(res);
          Swal.fire({
            title:
              "Anda telah berjaya mendaftar dengan e-learning Tabung Haji.",
            confirmButtonText: "Teruskan",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push({
                path: `/auth/login`,
              });
              //   this.$router.push({ name: "login" });
            }
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getUser() {
      console.log(this.firstCredential);
      const data = this.firstCredential;
      API.post(`auth/local`, this.firstCredential)
        .then((res) => {
            console.log(res.data.user.confirmed)
            if(!res.data.user.confirmed){
                this.user = res.data;
            } else {
                console.log('Pengguna ini telah didaftarkan, sila ke laman log masuk.')
                this.errors.confirm = "Pengguna ini telah didaftarkan, sila ke laman log masuk.";
            }
        })
        .catch((e) => {
          console.log(e);
          this.errors.confirm = "No K/P atau No Bakal Haji tidak sepadan.";
        });
    },
  },
};
</script>


<style lang="scss" scoped>
@import "./../../assets/scss/color.scss";

form {
  width: 330px;

  h3 {
    color: $gray-dark;
  }

  span {
    color: $gray-reqular;
  }
  label {
    color: $gray-dark-03;
  }

  .error-text {
    margin-top: 4px;
    font-size: 12px;
  }

  a {
    color: $gray-light !important;
    text-decoration: unset;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>