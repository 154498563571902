<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6">
                    <login-comp v-if="$route.path == '/auth/login'" />
                    <register-comp v-if="$route.path == '/auth/register'" />
                    <forgot-password-comp v-if="$route.path == '/auth/forgot-password'" />
                    <reset-password-comp v-if="$route.path == '/auth/reset-password'" />
                    <first-time v-if="$route.path == '/auth/first-time'" />
                </div>
                <div class="col-md-6 px-0 d-none d-sm-none d-md-block">
                    <img src="./../../assets/img/login_bg.png" alt="Login image" class="w-100 vh-100"
                        style="object-fit: cover; object-position: left;">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FirstTime from '../../components/main-auth/FirstTime.vue'
import LoginComp from './../../components/main-auth/Login.vue'
import RegisterComp from './../../components/main-auth/Register.vue'
import ForgotPasswordComp from '../../components/main-auth/ForgotPassword.vue'
import ResetPasswordComp from '../../components/main-auth/ResetPassword.vue'

export default {
    components: {
        LoginComp,
        RegisterComp,
        ForgotPasswordComp,
        ResetPasswordComp,
        FirstTime
    }
}
</script>