<template>
  <div class="h-100 overflow-auto pt-5 pb-3 px-3">
    <div class="text-center pb-4">
      <img src="@/assets/logo_2.svg" class="mb-5" />

      <p class="h4 fw-bold">Set Semula Kata Laluan</p>
    </div>

    <div class="reset-form">
      <div class="mb-3">
        <label class="form-label" for="models.password">Kata Laluan Baru</label>
        <input
          type="password"
          class="form-control"
          id="models.password"
          v-model="models.password"
          :disabled="isLoading"
        />
      </div>
      <div class="mb-3">
        <label class="form-label" for="models.password">
          Masukkan Kata Laluan Baru Sekali Lagi
        </label>
        <input
          type="password"
          class="form-control"
          id="models.passwordConfirmation"
          v-model="models.passwordConfirmation"
          :disabled="isLoading"
        />
      </div>
      <button
        class="btn btn-success text-light mx-auto d-block"
        @click="submit()"
        :disabled="isLoading"
      >
        <span v-if="isLoading" class="spinner-border spinner-border-sm"></span>
        <span v-else>Set Semula</span>
      </button>
      <div class="mt-4 text-center">
        <router-link to="/auth/login" class="text-muted">
          Kembali ke daftar masuk
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import API from "@/utils/API";

export default {
  data() {
    return {
      isLoading: false,
      models: {
        password: "",
        passwordConfirmation: "",
      },
    };
  },
  methods: {
    async submit() {
      if (
        !this.models.password ||
        !this.models.passwordConfirmation ||
        this.models.password !== this.models.passwordConfirmation
      ) {
        return Swal.fire({
          html: "<h5>Kata Laluan Tidak Sah</h5>",
          icon: "error",
        });
      } else {
        this.isLoading = true;

        await API.forgetPassword("auth/reset-password", {
          code: this.$route.query.code,
          ...this.models,
        })
          .then((response) => {
            Swal.fire({
              title: "<h4>Kata Laluan Berjaya Dikemaskini</h4>",
              html: "<h6>Sila daftar masuk menggunakan kata laluan anda yang baru</h6>",
              icon: "success",
            }).then(() => {
              this.isLoading = false;

              this.$router.push("/auth/login");
            });
          })
          .catch((error) => {
            console.error("An error occurred:", error.response);

            Swal.fire({
              title: "<h4>Kata Laluan Gagal Dikemaskini</h4>",
              html: "<h6>Sila cuba semula atau hubungi pegawai kami untuk bantuan</h6>",
              icon: "error",
            }).then(() => {
              this.isLoading = false;
            });
          });
      }
    },
  },
};
</script>