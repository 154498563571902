<template>
  <div class="h-100 overflow-auto pt-5 pb-3 px-3">
    <div class="text-center">
      <img src="@/assets/logo_2.svg" class="mb-4" />

      <p class="h4 fw-bold">Daftar Sebagai Ahli</p>
      <p class="h6 text-muted">Sila masukkan butiran anda</p>
    </div>

    <div class="register-form">
      <div class="mb-3">
        <label for="models.email" class="form-label">Email anda</label>
        <input
          type="email"
          class="form-control"
          id="models.email"
          placeholder="Masukkan email anda"
          v-model="models.email"
          :disabled="isLoading"
        />
      </div>
      <div class="mb-3">
        <label for="models.full_name" class="form-label">
          Nama Penuh (Berdasarkan K/P)
        </label>
        <input
          type="email"
          class="form-control"
          id="models.full_name"
          placeholder="Masukkan nama penuh anda"
          v-model="models.full_name"
          :disabled="isLoading"
        />
      </div>
      <div class="mb-3">
        <label for="models.password" class="form-label">Kata Laluan</label>
        <input
          type="password"
          class="form-control"
          id="models.password"
          placeholder="··········"
          v-model="models.password"
          :disabled="isLoading"
        />
      </div>
      <div class="mb-3">
        <label for="confirmPassword" class="form-label">
          Masukkan kata laluan sekali lagi
        </label>
        <input
          type="password"
          class="form-control"
          id="models.password"
          placeholder="··········"
          v-model="confirmPassword"
          :disabled="isLoading"
        />
      </div>
      <button
        class="btn btn-success w-100 text-light"
        @click="register()"
        :disabled="isLoading"
      >
        <span v-if="isLoading" class="spinner-border spinner-border-sm"></span>
        <span v-else>Daftar</span>
      </button>
      <router-link
        to="/auth/login"
        class="btn btn-link w-100 text-secondary text-decoration-none"
      >
        Kembali ke daftar masuk
      </router-link>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      models: {
        email: null,
        full_name: null,
        password: null,
      },
      confirmPassword: null,
      isLoading: false,
    };
  },
  methods: {
    ...mapActions(["authRegister"]),
    async register() {
      if (this.isLoading) return;

      this.isLoading = true;
      try {
        if (Object.values(this.models).some((model) => !model)) {
          Swal.fire({
            icon: "error",
            title: "",
            text: "Semua butiran wajib diisi",
          });
        } else if (this.models.password != this.confirmPassword) {
          Swal.fire({
            icon: "error",
            title: "",
            text: "Kata laluan tidak sepadan",
          });
        } else {
          const { data, error } = await this.authRegister(this.models);

          if (error) {
            throw error;
          }

          Swal.fire({
            icon: "success",
            title: "Terima kasih!",
            text: "Akaun anda telah berjaya didaftarkan",
          }).then(() => {
            this.$router.push("/");
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Akaun gagal didaftarkan",
          text: error,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.register-form {
  max-width: 350px;
  margin: 0 auto;
  padding-top: 1rem;
}

.form-label {
  margin-bottom: 0.25rem;
}
</style>